/* tslint:disable */
/* eslint-disable */
/**
 * Laravel
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosInstance, AxiosPromise } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError } from './base';

/**
 *
 * @export
 * @interface AnonymousCompany
 */
export interface AnonymousCompany {
  /**
   *
   * @type {AnonymousCompanyData}
   * @memberof AnonymousCompany
   */
  data?: AnonymousCompanyData;
}

/**
 *
 * @export
 * @interface AnonymousCompanyData
 */
export interface AnonymousCompanyData {
  reviewsCount?: number;
  googleReviewsCount?: number;
  /**
   *
   * @type {number}
   * @memberof AnonymousCompanyData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  stripeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  streetNr?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  latitude?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  longitude?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  vat?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  mobile?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  foundedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  about?: string | null;
  /**
   *
   * @type {number}
   * @memberof AnonymousCompanyData
   */
  numEmployees?: number | null;
  /**
   *
   * @type {number}
   * @memberof AnonymousCompanyData
   */
  governmentNr?: number | null;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  logo?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  cover?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  lastSocialShare?: MediaResponse;
  /**
   *
   * @type {AnonymousCompanyDataRelations}
   * @memberof AnonymousCompanyData
   */
  relations?: AnonymousCompanyDataRelations | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  facebook?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  twitter?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  instagram?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  linkedin?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  pinterest?: string | null;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  vca?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  iso9001?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  iso14001?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof AnonymousCompanyData
   */
  atg?: MediaResponse;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  claimedAt?: string | null;
  /**
   *
   * @type {GooglePlace}
   * @memberof AnonymousCompanyData
   */
  googlePlace?: GooglePlace | null;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousCompanyData
   */
  googleReviews?: boolean;
}

/**
 *
 * @export
 * @interface GooglePlace
 */
export interface GooglePlace {
  /**
   *
   * @type {string}
   * @memberof GooglePlace
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof GooglePlace
   */
  place_id?: string;
  /**
   *
   * @type {number}
   * @memberof GooglePlace
   */
  rating?: number;
  /**
   *
   * @type {number}
   * @memberof GooglePlace
   */
  user_ratings_total?: number;
}

/**
 *
 * @export
 * @interface AnonymousCompanyDataRelations
 */
export interface AnonymousCompanyDataRelations {
  /**
   *
   * @type {Array<Sector>}
   * @memberof AnonymousCompanyDataRelations
   */
  sectors?: Array<Sector>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof AnonymousCompanyDataRelations
   */
  externalTags?: Array<Tag>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof CompanyDataRelations
   */
  internalTags?: Array<Tag>;
  /**
   *
   * @type {Realisation}
   * @memberof AnonymousCompanyDataRelations
   */
  recentRealisation?: Realisation;
  /**
   *
   * @type {AnonymousSubscription}
   * @memberof AnonymousCompanyDataRelations
   */
  subscription?: AnonymousSubscription;
}

/**
 *
 * @export
 * @interface AnonymousSubscription
 */
export interface AnonymousSubscription {
  /**
   *
   * @type {AnonymousSubscriptionData}
   * @memberof AnonymousSubscription
   */
  data?: AnonymousSubscriptionData;
}

/**
 *
 * @export
 * @interface AnonymousSubscriptionData
 */
export interface AnonymousSubscriptionData {
  /**
   *
   * @type {AnonymousSubscriptionDataFeatures}
   * @memberof AnonymousSubscriptionData
   */
  features?: AnonymousSubscriptionDataFeatures;
}

/**
 *
 * @export
 * @interface AnonymousSubscriptionDataFeatures
 */
export interface AnonymousSubscriptionDataFeatures {
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  premiumProfile?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  reviewNotification?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  socialShare?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  widgets?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  offlinePromo?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  qr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  realisations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  invitationsEmail?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  reviewRespond?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  invitationsWhatsapp?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  app?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousSubscriptionDataFeatures
   */
  users?: boolean;
}

/**
 *
 * @export
 * @interface ApiCompaniesInviteForReviewInvitations
 */
export interface ApiCompaniesInviteForReviewInvitations {
  /**
   *
   * @type {string}
   * @memberof ApiCompaniesInviteForReviewInvitations
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ApiCompaniesInviteForReviewInvitations
   */
  email?: string;
}

/**
 *
 * @export
 * @interface ApiReviewImages
 */
export interface ApiReviewImages {
  /**
   *
   * @type {string}
   * @memberof ApiReviewImages
   */
  base64?: string;
  thumbnail?: File;
}

export interface ApiReviewVideo {
  /**
   *
   * @type {string}
   * @memberof ApiReviewVideo
   */
  file?: FormData;
  thumbnail?: FormData;
}

/**
 *
 * @export
 * @interface Article
 */
export interface Article {
  /**
   *
   * @type {ArticleData}
   * @memberof Article
   */
  data?: ArticleData;
}

/**
 *
 * @export
 * @interface ArticleData
 */
export interface ArticleData {
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  type?: string | null;
  /**
   *
   * @type {number}
   * @memberof ArticleData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  language?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  subtitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  publicationState?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ArticleData
   */
  isFavorite?: boolean;
  /**
   *
   * @type {MediaResponse}
   * @memberof ArticleData
   */
  cover?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof ArticleData
   */
  banner?: MediaResponse;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  slug?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof ArticleData
   */
  flexibleContent?: Array<object>;
  /**
   *
   * @type {ArticleDataRelations}
   * @memberof ArticleData
   */
  relations?: ArticleDataRelations | null;
  /**
   *
   * @type {ArticleDataMetaTags}
   * @memberof ArticleData
   */
  metaTags?: ArticleDataMetaTags | null;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleData
   */
  publishedAt?: string | null;
}

/**
 *
 * @export
 * @interface ArticleDataMetaTags
 */
export interface ArticleDataMetaTags {
  /**
   *
   * @type {string}
   * @memberof ArticleDataMetaTags
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ArticleDataMetaTags
   */
  description?: string;
}

/**
 *
 * @export
 * @interface ArticleDataRelations
 */
export interface ArticleDataRelations {
  /**
   *
   * @type {Company}
   * @memberof ArticleDataRelations
   */
  company?: Company;
  /**
   *
   * @type {Array<object>}
   * @memberof ArticleDataRelations
   */
  otherPublications?: Array<object> | null;
}

/**
 *
 * @export
 * @interface BasicPage
 */
export interface BasicPage {
  /**
   *
   * @type {BasicPageData}
   * @memberof BasicPage
   */
  data?: BasicPageData;
}

/**
 *
 * @export
 * @interface BasicPageData
 */
export interface BasicPageData {
  /**
   *
   * @type {number}
   * @memberof BasicPageData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof BasicPageData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof BasicPageData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof BasicPageData
   */
  uid?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof BasicPageData
   */
  flexibleContent?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof BasicPageData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof BasicPageData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface StripeAccessUrl
 */
export interface StripeAccessUrl {
  /**
   *
   * @type {StripeAccessUrlData}
   * @memberof StripeAccessUrl
   */
  data?: StripeAccessUrlData;
}

/**
 *
 * @export
 * @interface StripeAccessUrlData
 */
export interface StripeAccessUrlData {
  /**
   *
   * @type {string}
   * @memberof StripeAccessUrlData
   */
  accessUrl?: string;
}

/**
 *
 * @export
 * @interface CodeVerification
 */
export interface CodeVerification {
  /**
   *
   * @type {string}
   * @memberof CodeVerification
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CodeVerification
   */
  code?: string;
}

/**
 *
 * @export
 * @interface Company
 */
export interface Company {
  /**
   *
   * @type {CompanyData}
   * @memberof Company
   */
  data?: CompanyData;
  url?: string;
}

/**
 *
 * @export
 * @interface CompanyData
 */
export interface CompanyData {
  /**
   *
   * @type {number}
   * @memberof CompanyData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  stripeId?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  streetNr?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  province?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  latitude?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  longitude?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  vat?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  mobile?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  foundedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  about?: string | null;
  /**
   *
   * @type {number}
   * @memberof CompanyData
   */
  numEmployees?: number | null;
  /**
   *
   * @type {number}
   * @memberof CompanyData
   */
  governmentNr?: number | null;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  logo?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  cover?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  lastSocialShare?: MediaResponse;
  /**
   *
   * @type {CompanyDataRelations}
   * @memberof CompanyData
   */
  relations?: CompanyDataRelations | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  facebook?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  twitter?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  instagram?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  linkedin?: string | null;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  pinterest?: string | null;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  vca?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  iso9001?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  iso14001?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof CompanyData
   */
  atg?: MediaResponse;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof CompanyData
   */
  claimedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof AnonymousCompanyData
   */
  placeId?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof AnonymousCompanyData
   */
  googleReviews?: boolean;
}

/**
 *
 * @export
 * @interface CompanyDataRelations
 */
export interface CompanyDataRelations {
  /**
   *
   * @type {Array<Sector>}
   * @memberof CompanyDataRelations
   */
  sectors?: Array<Sector>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof CompanyDataRelations
   */
  externalTags?: Array<Tag>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof CompanyDataRelations
   */
  internalTags?: Array<Tag>;
  /**
   *
   * @type {Realisation}
   * @memberof CompanyDataRelations
   */
  recentRealisation?: Realisation;
  /**
   *
   * @type {EmptyUser}
   * @memberof CompanyDataRelations
   */
  user?: EmptyUser;
  /**
   *
   * @type {Subscription}
   * @memberof CompanyDataRelations
   */
  subscription?: Subscription;
}

/**
 *
 * @export
 * @interface Complaint
 */
export interface Complaint {
  /**
   *
   * @type {ComplaintData}
   * @memberof Complaint
   */
  data?: ComplaintData;
}

/**
 *
 * @export
 * @interface ComplaintData
 */
export interface ComplaintData {
  /**
   *
   * @type {number}
   * @memberof ComplaintData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface ComplaintRequest
 */
export interface ComplaintRequest {
  /**
   *
   * @type {string}
   * @memberof ComplaintRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ComplaintRequest
   */
  message?: string;
}

/**
 *
 * @export
 * @interface Count
 */
export interface Count {
  /**
   *
   * @type {number}
   * @memberof Count
   */
  count?: number;
}

/**
 *
 * @export
 * @interface EmployeeRequest
 */
export interface EmployeeRequest {
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeRequest
   */
  lastName?: string;
}

/**
 *
 * @export
 * @interface EmptyUser
 */
export interface EmptyUser {
  /**
   *
   * @type {EmptyUserData}
   * @memberof EmptyUser
   */
  data?: EmptyUserData;
}

/**
 *
 * @export
 * @interface EmptyUserData
 */
export interface EmptyUserData {
  /**
   *
   * @type {number}
   * @memberof EmptyUserData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  screenName?: string;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof EmptyUserData
   */
  newsletterSubscription?: boolean;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  emailVerifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  privacyApprovedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof EmptyUserData
   */
  updatedAt?: string;
  /**
   *
   * @type {object}
   * @memberof EmptyUserData
   */
  relations?: object | null;
}

/**
 *
 * @export
 * @interface Feedback
 */
export interface Feedback {
  /**
   *
   * @type {FeedbackData}
   * @memberof Feedback
   */
  data?: FeedbackData;
}

/**
 *
 * @export
 * @interface FeedbackData
 */
export interface FeedbackData {
  /**
   *
   * @type {number}
   * @memberof FeedbackData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FeedbackData
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof FeedbackData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface FileCreation
 */
export interface FileCreation {
  /**
   *
   * @type {string}
   * @memberof FileCreation
   */
  file?: string;
  /**
   * one of:vca,iso9001,iso14001,atg
   * @type {string}
   * @memberof FileCreation
   */
  collection?: string;
  /**
   * one of: company
   * @type {string}
   * @memberof FileCreation
   */
  model?: string;
  /**
   *
   * @type {number}
   * @memberof FileCreation
   */
  modelId?: number;
}

/**
 *
 * @export
 * @interface ForgotPasswordRequest
 */
export interface ForgotPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ForgotPasswordRequest
   */
  email?: string;
}

/**
 *
 * @export
 * @interface ImageCreation
 */
export interface ImageCreation {
  /**
   *
   * @type {string}
   * @memberof ImageCreation
   */
  photo?: string;
  /**
   * logo, cover, social_shares, images
   * @type {string}
   * @memberof ImageCreation
   */
  collection?: string;
  /**
   * realisation, company, review
   * @type {string}
   * @memberof ImageCreation
   */
  model?: string;
}

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  email?: string;
}

/**
 *
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
  /**
   *
   * @type {string}
   * @memberof InlineObject1
   */
  email?: string;
}

/**
 *
 * @export
 * @interface InlineObject10
 */
export interface InlineObject10 {
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  streetNr?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  province?: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject10
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof InlineObject10
   */
  longitude?: number | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  vat?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  mobile?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  foundedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  about?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  numEmployees?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  governmentNr?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof InlineObject10
   */
  sectorIds?: Array<number> | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  userEmail?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  userPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  userPasswordConfirmation?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  userFirstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  userLastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  facebook?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  twitter?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  instagram?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  linkedin?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject10
   */
  pinterest?: string | null;
}

/**
 *
 * @export
 * @interface InlineObject11
 */
export interface InlineObject11 {
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  streetNr?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject11
   */
  message?: string;
}

/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {number}
   * @memberof InlineObject2
   */
  planId?: number;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  vat?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  street?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  streetNr?: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject2
   */
  companyId?: number | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject2
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof InlineObject2
   */
  longitude?: number | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userEmail?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userPassword?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userPasswordConfirmation?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userLastName?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  userMobile?: string | null;
  /**
   *
   * @type {string}
   * @memberof InlineObject2
   */
  mobile?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof InlineObject2
   */
  sectorIds?: Array<number> | null;
  /**
   *
   * @type {boolean}
   * @memberof InlineObject2
   */
  acceptPolicy?: boolean | null;
}

/**
 *
 * @export
 * @interface InlineObject3
 */
export interface ReviewForm {
  /**
   *
   * @type {string}
   * @memberof String
   */
  type?: String;
  /**
   *
   * @type {object}
   * @memberof Object
   */
  data?: Object;
}

export interface InlineObject3 {
  /**
   *
   * @type {number}
   * @memberof InlineObject3
   */
  companyId?: number;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof InlineObject3
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  screenName?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof InlineObject3
   */
  email?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof InlineObject3
   */
  pointsProConIds?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof InlineObject3
   */
  sectorIds?: Array<number>;
  /**
   *
   * @type {Array<ApiReviewImages>}
   * @memberof InlineObject3
   */
  images?: Array<ApiReviewImages> | null;
  /**
   *
   * @type {Array<ApiReviewVideo>}
   * @memberof InlineObject3
   */
  video?: Array<ApiReviewVideo> | null;
}

/**
 *
 * @export
 * @interface InlineObject4
 */
export interface InlineObject4 {
  /**
   *
   * @type {string}
   * @memberof InlineObject4
   */
  email?: string;
}

/**
 *
 * @export
 * @interface InlineObject5
 */
export interface InlineObject5 {
  /**
   *
   * @type {string}
   * @memberof InlineObject5
   */
  message?: string;
}

/**
 *
 * @export
 * @interface InlineObject6
 */
export interface InlineObject6 {
  /**
   *
   * @type {string}
   * @memberof InlineObject6
   */
  message?: string;
}

/**
 *
 * @export
 * @interface InlineObject7
 */
export interface InlineObject7 {
  /**
   *
   * @type {string}
   * @memberof InlineObject7
   */
  title?: string;
  /**
   * pro, con
   * @type {string}
   * @memberof InlineObject7
   */
  type?: string;
}

/**
 *
 * @export
 * @interface InlineObject8
 */
export interface InlineObject8 {
  /**
   *
   * @type {any}
   * @memberof InlineObject8
   */
  file?: any;
  /**
   *
   * @type {string}
   * @memberof InlineObject8
   */
  gdprAccepted?: string;
}

/**
 *
 * @export
 * @interface InlineObject9
 */
export interface InlineObject9 {
  /**
   *
   * @type {Array<ApiCompaniesInviteForReviewInvitations>}
   * @memberof InlineObject9
   */
  invitations?: Array<ApiCompaniesInviteForReviewInvitations>;
  /**
   *
   * @type {boolean}
   * @memberof InlineObject9
   */
  gdprAccepted?: boolean;
}

/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {Array<JobPage>}
   * @memberof InlineResponse200
   */
  data?: Array<JobPage>;
}

/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {Array<Article>}
   * @memberof InlineResponse2001
   */
  data?: Array<Article>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof InlineResponse2001
   */
  meta?: PaginationMeta;
}

/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {Array<Plan>}
   * @memberof InlineResponse2002
   */
  data?: Array<Plan>;
}

/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {Array<Realisation>}
   * @memberof InlineResponse2003
   */
  data?: Array<Realisation>;
}

/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {Array<Review>}
   * @memberof InlineResponse2004
   */
  data?: Array<Review>;
}

/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {Array<Sector>}
   * @memberof InlineResponse2005
   */
  data?: Array<Sector>;
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {InlineResponse2006Data}
   * @memberof InlineResponse2006
   */
  data?: InlineResponse2006Data;
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
  /**
   *
   * @type {Array<Company>}
   * @memberof InlineResponse2009
   */
  data?: Array<Company>;
  meta?: PaginationMeta;
}

/**
 *
 * @export
 * @interface InlineResponse2006Data
 */
export interface InlineResponse2006Data {
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  allowedNumberOfInvitesForReviews?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  numberOfInvitesRemainingForReviews?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  numberOfInvitesForReviews?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  allowedNumberOfRealisations?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  numberOfRealisationsRemaining?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  numberOfRealisations?: number;
  /**
   *
   * @type {number}
   * @memberof InlineResponse2006Data
   */
  totalReviews?: number;
  /**
   *
   * @type {Array<Review>}
   * @memberof InlineResponse2006Data
   */
  recentReviews?: Array<Review>;
  /**
   *
   * @type {User}
   * @memberof InlineResponse2006Data
   */
  trustManager?: User;
}

/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   *
   * @type {Array<User>}
   * @memberof InlineResponse2007
   */
  data?: Array<User>;
}

/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
  /**
   *
   * @type {Array<object>}
   * @memberof InlineResponse2008
   */
  data?: Array<object>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof InlineResponse2008
   */
  meta?: PaginationMeta;
}

/**
 *
 * @export
 * @interface JobPage
 */
export interface JobPage {
  /**
   *
   * @type {JobPageData}
   * @memberof JobPage
   */
  data?: JobPageData;
}

/**
 *
 * @export
 * @interface JobPageData
 */
export interface JobPageData {
  /**
   *
   * @type {number}
   * @memberof JobPageData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof JobPageData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof JobPageData
   */
  slug?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof JobPageData
   */
  flexibleContent?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof JobPageData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof JobPageData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  password?: string;
}

/**
 *
 * @export
 * @interface MediaResponse
 */
export interface MediaResponse {
  /**
   *
   * @type {MediaResponseData}
   * @memberof MediaResponse
   */
  data?: MediaResponseData;
}

/**
 *
 * @export
 * @interface MediaResponseData
 */
export interface MediaResponseData {
  /**
   *
   * @type {number}
   * @memberof MediaResponseData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof MediaResponseData
   */
  original?: string;
  /**
   *
   * @type {string}
   * @memberof MediaResponseData
   */
  fileName?: string;
  /**
   *
   * @type {object}
   * @memberof MediaResponseData
   */
  customProperties?: {
    thumbnail: {
      data: {
        id: number;
        fileName: string;
        original: string;
        mime_type: string;
      };
    };
  } | null;
  /**
   *
   * @type {Array<MediaResponseDataConversions>}
   * @memberof MediaResponseData
   */
  conversions?: Array<MediaResponseDataConversions> | null;
}

/**
 *
 * @export
 * @interface MediaResponseDataConversions
 */
export interface MediaResponseDataConversions {
  /**
   *
   * @type {string}
   * @memberof MediaResponseDataConversions
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof MediaResponseDataConversions
   */
  url?: string;
}

/**
 *
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  message?: string;
}

/**
 *
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  currentPage?: number;
  /**
   *
   * @type {string}
   * @memberof PaginationMeta
   */
  firstPageUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  from?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  lastPage?: number;
  /**
   *
   * @type {string}
   * @memberof PaginationMeta
   */
  lastPageUrl?: string | null;
  /**
   *
   * @type {Array<PaginationMetaLinks>}
   * @memberof PaginationMeta
   */
  links?: Array<PaginationMetaLinks>;
  /**
   *
   * @type {string}
   * @memberof PaginationMeta
   */
  nextPageUrl?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginationMeta
   */
  path?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  perPage?: number;
  /**
   *
   * @type {string}
   * @memberof PaginationMeta
   */
  prevPageUrl?: string | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  to?: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  total?: number;
}

/**
 *
 * @export
 * @interface PaginationMetaLinks
 */
export interface PaginationMetaLinks {
  /**
   *
   * @type {string}
   * @memberof PaginationMetaLinks
   */
  url?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginationMetaLinks
   */
  label?: string;
  /**
   *
   * @type {boolean}
   * @memberof PaginationMetaLinks
   */
  active?: boolean;
}

/**
 *
 * @export
 * @interface PhoneVerification
 */
export interface PhoneVerification {
  /**
   *
   * @type {string}
   * @memberof PhoneVerification
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof PhoneVerification
   */
  code?: number;
}

/**
 *
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {PlanData}
   * @memberof Plan
   */
  data?: PlanData;
}

/**
 *
 * @export
 * @interface PlanData
 */
export interface PlanData {
  /**
   *
   * @type {number}
   * @memberof PlanData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  uid?: string;
  /**
   *
   * @type {number}
   * @memberof PlanData
   */
  price?: number;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  stripeId?: string | null;
  /**
   *
   * @type {AnonymousSubscriptionDataFeatures}
   * @memberof PlanData
   */
  features?: AnonymousSubscriptionDataFeatures;
  /**
   *
   * @type {boolean}
   * @memberof PlanData
   */
  isPublic?: boolean;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof PlanData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface ProConPoint
 */
export interface ProConPoint {
  /**
   *
   * @type {ProConPointData}
   * @memberof ProConPoint
   */
  data?: ProConPointData;
}

/**
 *
 * @export
 * @interface ProConPointData
 */
export interface ProConPointData {
  /**
   *
   * @type {number}
   * @memberof ProConPointData
   */
  id?: number;
  label?: string;
  points?: Array<any>;
  /**
   *
   * @type {string}
   * @memberof ProConPointData
   */
  title?: string;
  /**
   * oneof: \"pro\" or \"con\"
   * @type {string}
   * @memberof ProConPointData
   */
  type?: string;
  /**
   *
   * @type {boolean}
   * @memberof ProConPointData
   */
  isOfficial?: boolean;
  /**
   *
   * @type {string}
   * @memberof ProConPointData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ProConPointData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface Realisation
 */
export interface Realisation {
  /**
   *
   * @type {RealisationData}
   * @memberof Realisation
   */
  data?: RealisationData;
}

/**
 *
 * @export
 * @interface RealisationCreation
 */
export interface RealisationCreation {
  /**
   *
   * @type {string}
   * @memberof RealisationCreation
   */
  subtitle?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationCreation
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationCreation
   */
  body?: string;
  /**
   *
   * @type {number}
   * @memberof RealisationCreation
   */
  companyId?: number;
  /**
   *
   * @type {string}
   * @memberof RealisationCreation
   */
  cover?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof RealisationCreation
   */
  sectorIds?: Array<number>;
  /**
   *
   * @type {Array<RealisationCreationImages>}
   * @memberof RealisationCreation
   */
  images?: Array<RealisationCreationImages> | null;
}

/**
 *
 * @export
 * @interface RealisationCreationImages
 */
export interface RealisationCreationImages {
  /**
   *
   * @type {number}
   * @memberof RealisationCreationImages
   */
  id?: number | null;
  /**
   *
   * @type {string}
   * @memberof RealisationCreationImages
   */
  base64?: string;
}

/**
 *
 * @export
 * @interface RealisationData
 */
export interface RealisationData {
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  type?: string | null;
  /**
   *
   * @type {number}
   * @memberof RealisationData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  title?: string;
  /**
   *
   * @type {boolean}
   * @memberof RealisationData
   */
  isPublished?: boolean;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  body?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  subtitle?: string;
  /**
   *
   * @type {MediaResponse}
   * @memberof RealisationData
   */
  cover?: MediaResponse;
  /**
   *
   * @type {Array<MediaResponse>}
   * @memberof RealisationData
   */
  images?: Array<MediaResponse>;
  /**
   *
   * @type {RealisationDataRelations}
   * @memberof RealisationData
   */
  relations?: RealisationDataRelations | null;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof RealisationData
   */
  publishedAt?: string | null;
}

/**
 *
 * @export
 * @interface RealisationDataRelations
 */
export interface RealisationDataRelations {
  /**
   *
   * @type {Array<Sector>}
   * @memberof RealisationDataRelations
   */
  sectors?: Array<Sector>;
  /**
   *
   * @type {Array<object>}
   * @memberof RealisationDataRelations
   */
  otherPublications?: Array<object> | null;
}

/**
 *
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  passwordConfirmation?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  screenName?: string;
  /**
   *
   * @type {boolean}
   * @memberof RegisterRequest
   */
  newsletterSubscription?: boolean;
}

/**
 *
 * @export
 * @interface RequestSector
 */
export interface RequestSector {
  /**
   *
   * @type {string}
   * @memberof RequestSector
   */
  sector?: string;
}

/**
 *
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof ResetPasswordRequest
   */
  passwordConfirmation?: string;
}

/**
 *
 * @export
 * @interface Review
 */
export interface Review {
  /**
   *
   * @type {ReviewData}
   * @memberof Review
   */
  data?: ReviewData;
}

/**
 *
 * @export
 * @interface ReviewData
 */
export interface ReviewData {
  authorImage?: string;
  origin?: 'web' | 'app' | 'google';
  hasComplaint?: boolean;
  /**
   *
   * @type {number}
   * @memberof ReviewData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  city?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  typeOfWork?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  collaberation?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  originalLanguage?: string;
  /**
   * one of: pending,approved,complained,rejected,archived
   * @type {string}
   * @memberof ReviewData
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof ReviewData
   */
  rating?: number;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  screenName?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  initials?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReviewData
   */
  isReadByUser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ReviewData
   */
  isFavorite?: boolean;
  /**
   *
   * @type {number}
   * @memberof ReviewData
   */
  totalFeedbackCount?: number;
  /**
   *
   * @type {MediaResponse}
   * @memberof ReviewData
   */
  lastSocialShare?: MediaResponse;
  /**
   *
   * @type {ReviewDataRelations}
   * @memberof ReviewData
   */
  relations?: ReviewDataRelations | null;
  /**
   *
   * @type {Array<MediaResponse>}
   * @memberof ReviewData
   */
  audio?: Array<MediaResponse>;
  /**
   *
   * @type {Array<MediaResponse>}
   * @memberof ReviewData
   */
  video?: Array<MediaResponse>;
  /**
   *
   * @type {Array<MediaResponse>}
   * @memberof ReviewData
   */
  videoThumbnail?: Array<MediaResponse>;
  /**
   *
   * @type {Array<MediaResponse>}
   * @memberof ReviewData
   */
  files?: Array<MediaResponse>;
  /**
   *
   * @type {MediaResponse}
   * @memberof ReviewData
   */
  picture?: MediaResponse;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  verifiedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof ReviewData
   */
  email?: string;
}

/**
 *
 * @export
 * @interface ReviewDataRelations
 */
export interface ReviewDataRelations {
  /**
   *
   * @type {Company}
   * @memberof ReviewDataRelations
   */
  company?: Company;
  /**
   *
   * @type {Array<Tag>}
   * @memberof ReviewDataRelations
   */
  tags?: Array<Tag> | null;
  /**
   *
   * @type {Array<ProConPoint>}
   * @memberof ReviewDataRelations
   */
  proConPoints?: Array<ProConPoint> | null;
  /**
   *
   * @type {Array<Sector>}
   * @memberof ReviewDataRelations
   */
  sectors?: Array<Sector> | null;
  /**
   *
   * @type {Feedback}
   * @memberof ReviewDataRelations
   */
  feedback?: Feedback;
  /**
   *
   * @type {Complaint}
   * @memberof ReviewDataRelations
   */
  complaint?: Complaint;
}

/**
 *
 * @export
 * @interface Score
 */
export interface Score {
  /**
   *
   * @type {ScoreData}
   * @memberof Score
   */
  data?: ScoreData | null;
}

/**
 *
 * @export
 * @interface ShortLinks
 */
export interface ShortLinks {
  /**
   *
   * @type {ScoreData}
   * @memberof Score
   */
  data?: ShortLinksData[] | null;
}

/**
 *
 * @export
 * @interface ScoreData
 */
export interface ScoreData {
  /**
   *
   * @type {number}
   * @memberof ScoreData
   */
  score?: number;
  /**
   *
   * @type {ScoreDataDistribution}
   * @memberof ScoreData
   */
  distribution?: ScoreDataDistribution;
  /**
   *
   * @type {string}
   * @memberof ScoreData
   */
  total?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreData
   */
  google_total?: string;
}

/**
 *
 * @export
 * @interface ScoreData
 */
export interface ShortLinksData {
  data: {
    /**
     *
     * @type {
     *   QrPng : {
     *     data : {
     *       original: string
     *     }
     *   }
     * }
     * @memberof ScoreData
     */
    QrPng?: {
      data: {
        original: string;
        fileName: string;
      };
    };
    /**
     *
     * @type {
     *   QrSvg : {
     *     data : {
     *       original: string
     *     }
     *   }
     * }
     * @memberof ScoreData
     */
    QrSvg?: {
      data: {
        original: string;
        fileName: string;
      };
    };
    /**
     *
     * @type {string}
     * @memberof ScoreData
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ScoreData
     */
    originalUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ScoreData
     */
    shortUrl?: string;
  };
}

/**
 *
 * @export
 * @interface ScoreDataDistribution
 */
export interface ScoreDataDistribution {
  /**
   *
   * @type {string}
   * @memberof ScoreDataDistribution
   */
  _1?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDataDistribution
   */
  _2?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDataDistribution
   */
  _3?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDataDistribution
   */
  _4?: string;
  /**
   *
   * @type {string}
   * @memberof ScoreDataDistribution
   */
  _5?: string;
}

/**
 *
 * @export
 * @interface Sector
 */
export interface Sector {
  /**
   *
   * @type {SectorData}
   * @memberof Sector
   */
  data?: SectorData;
}

/**
 *
 * @export
 * @interface SectorCouple
 */
export interface SectorCouple {
  /**
   *
   * @type {string}
   * @memberof SectorCouple
   */
  modelType?: string;
  /**
   *
   * @type {number}
   * @memberof SectorCouple
   */
  modelId?: number;
}

/**
 *
 * @export
 * @interface Sector
 */
export interface LeadForms {
  /**
   *
   * @type {SectorData}
   * @memberof Sector
   */
  data: LeadFormsData;
}

/**
 *
 * @export
 * @interface SectorData
 */
export interface LeadFormsData {
  affiliate?: string;
  affiliateRef?: string;
  country?: string;
  formId?: string;
  id: number;
  isActive?: number;
  language?: string;
  name?: string;
  sectorId?: number;
}

/**
 *
 * @export
 * @interface SectorData
 */
export interface SectorData {
  /**
   *
   * @type {number}
   * @memberof SectorData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  profession?: string;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  icon?: string | null;
  /**
   *
   * @type {SlugSchema}
   * @memberof SectorData
   */
  slug?: SlugSchema;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  description?: string | null;
  /**
   *
   * @type {number}
   * @memberof SectorData
   */
  parentId?: number | null;
  /**
   *
   * @type {Array<object>}
   * @memberof SectorData
   */
  descendants?: Array<object>;
  /**
   *
   * @type {Array<object>}
   * @memberof SectorData
   */
  flexibleContent?: Array<object>;
  /**
   *
   * @type {MediaResponse}
   * @memberof SectorData
   */
  cover?: MediaResponse;
  /**
   *
   * @type {MediaResponse}
   * @memberof SectorData
   */
  banner?: MediaResponse;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SectorData
   */
  updatedAt?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof SectorData
   */
  leadforms?: LeadForms[];
}

/**
 *
 * @export
 * @interface SlugSchema
 */
export interface SlugSchema {
  /**
   *
   * @type {SlugSchemaData}
   * @memberof SlugSchema
   */
  data?: SlugSchemaData;
}

/**
 *
 * @export
 * @interface SlugSchemaData
 */
export interface SlugSchemaData {
  /**
   *
   * @type {string}
   * @memberof SlugSchemaData
   */
  nl?: string;
  /**
   *
   * @type {string}
   * @memberof SlugSchemaData
   */
  en?: string;
  /**
   *
   * @type {string}
   * @memberof SlugSchemaData
   */
  fr?: string;
}

/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {SubscriptionData}
   * @memberof Subscription
   */
  data?: SubscriptionData;
}

/**
 *
 * @export
 * @interface SubscriptionData
 */
export interface SubscriptionData {
  /**
   *
   * @type {number}
   * @memberof SubscriptionData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SubscriptionData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionData
   */
  uid?: string;
  /**
   *
   * @type {number}
   * @memberof SubscriptionData
   */
  price?: number;
  /**
   *
   * @type {AnonymousSubscriptionDataFeatures}
   * @memberof SubscriptionData
   */
  features?: AnonymousSubscriptionDataFeatures;
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionData
   */
  isPublic?: boolean;
  /**
   *
   * @type {string}
   * @memberof SubscriptionData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof SubscriptionData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {TagData}
   * @memberof Tag
   */
  data?: TagData;
}

/**
 *
 * @export
 * @interface TagData
 */
export interface TagData {
  /**
   *
   * @type {number}
   * @memberof TagData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  icon?: string | null;
  /**
   *
   * @type {number}
   * @memberof TagData
   */
  order_column?: number;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof TagData
   */
  updatedAt?: string;
}

/**
 *
 * @export
 * @interface TagsCouple
 */
export interface TagsCouple {
  /**
   *
   * @type {Array<number>}
   * @memberof TagsCouple
   */
  tags?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof TagsCouple
   */
  modelType?: string;
  /**
   *
   * @type {number}
   * @memberof TagsCouple
   */
  modelId?: number;
}

/**
 *
 * @export
 * @interface Token
 */
export interface Token {
  /**
   *
   * @type {string}
   * @memberof Token
   */
  accessToken?: string;
  /**
   *
   * @type {string}
   * @memberof Token
   */
  tokenType?: string;
  /**
   *
   * @type {number}
   * @memberof Token
   */
  expiresIn?: number;
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {UserData}
   * @memberof User
   */
  data?: UserData;
}

/**
 *
 * @export
 * @interface UserData
 */
export interface UserData {
  /**
   *
   * @type {number}
   * @memberof UserData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  screenName?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  mobile?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UserData
   */
  newsletterSubscription?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  emailVerifiedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  privacyApprovedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof UserData
   */
  lastLoginAt?: string | null;
  /**
   *
   * @type {UserDataRelations}
   * @memberof UserData
   */
  relations?: UserDataRelations | null;
}

/**
 *
 * @export
 * @interface UserDataRelations
 */
export interface UserDataRelations {
  /**
   *
   * @type {Company}
   * @memberof UserDataRelations
   */
  company?: Company;
}

/**
 *
 * @export
 * @interface UserHasReadSchema
 */
export interface UserHasReadSchema {
  /**
   * one of: review
   * @type {string}
   * @memberof UserHasReadSchema
   */
  modelType?: string;
  /**
   *
   * @type {number}
   * @memberof UserHasReadSchema
   */
  modelId?: number;
}

/**
 * ArticlesApi - axios parameter creator
 * @export
 */
export const ArticlesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get an article
     * @param {string} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleArticleGet: async (article: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'article' is not null or undefined
      assertParamExists('apiArticleArticleGet', 'article', article);
      const localVarPath = `/api/article/{article}`.replace(
        `{${'article'}}`,
        encodeURIComponent(String(article))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleGet: async (
      date?: string,
      perPage?: number,
      page?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/article`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get most read articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleMostReadGet: async (
      date?: string,
      perPage?: number,
      page?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/article/most-read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary get an article preview
     * @param {string} uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleUidPreviewGet: async (uid: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'uid' is not null or undefined
      assertParamExists('apiArticleUidPreviewGet', 'uid', uid);
      const localVarPath = `/api/article/{uid}/preview`.replace(
        `{${'uid'}}`,
        encodeURIComponent(String(uid))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Articles for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorArticlesGet: async (
      sector: string,
      date?: string,
      perPage?: number,
      page?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorArticlesGet', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/articles`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ArticlesApi - functional programming interface
 * @export
 */
export const ArticlesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ArticlesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary get an article
     * @param {string} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiArticleArticleGet(
      article: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticleArticleGet(
        article,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiArticleGet(
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticleGet(
        date,
        perPage,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get most read articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiArticleMostReadGet(
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticleMostReadGet(
        date,
        perPage,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary get an article preview
     * @param {string} uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiArticleUidPreviewGet(
      uid: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Article>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiArticleUidPreviewGet(
        uid,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Articles for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorArticlesGet(
      sector: string,
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorArticlesGet(
        sector,
        date,
        perPage,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ArticlesApi - factory interface
 * @export
 */
export const ArticlesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ArticlesApiFp(configuration);
  return {
    /**
     *
     * @summary get an article
     * @param {string} article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleArticleGet(article: string, options?: any): AxiosPromise<Article> {
      return localVarFp
        .apiArticleArticleGet(article, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleGet(
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .apiArticleGet(date, perPage, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get most read articles.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleMostReadGet(
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .apiArticleMostReadGet(date, perPage, page, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary get an article preview
     * @param {string} uid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiArticleUidPreviewGet(uid: string, options?: any): AxiosPromise<Article> {
      return localVarFp
        .apiArticleUidPreviewGet(uid, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Articles for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [page] Page number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorArticlesGet(
      sector: string,
      date?: string,
      perPage?: number,
      page?: number,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .apiSectorSectorArticlesGet(sector, date, perPage, page, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ArticlesApi - object-oriented interface
 * @export
 * @class ArticlesApi
 * @extends {BaseAPI}
 */
export class ArticlesApi extends BaseAPI {
  /**
   *
   * @summary get an article
   * @param {string} article
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public apiArticleArticleGet(article: string, options?: any) {
    return ArticlesApiFp(this.configuration)
      .apiArticleArticleGet(article, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Articles.
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {number} [page] Page number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public apiArticleGet(date?: string, perPage?: number, page?: number, options?: any) {
    return ArticlesApiFp(this.configuration)
      .apiArticleGet(date, perPage, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get most read articles.
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {number} [page] Page number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public apiArticleMostReadGet(date?: string, perPage?: number, page?: number, options?: any) {
    return ArticlesApiFp(this.configuration)
      .apiArticleMostReadGet(date, perPage, page, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary get an article preview
   * @param {string} uid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public apiArticleUidPreviewGet(uid: string, options?: any) {
    return ArticlesApiFp(this.configuration)
      .apiArticleUidPreviewGet(uid, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Articles for a sector.
   * @param {string} sector
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {number} [page] Page number
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ArticlesApi
   */
  public apiSectorSectorArticlesGet(
    sector: string,
    date?: string,
    perPage?: number,
    page?: number,
    options?: any
  ) {
    return ArticlesApiFp(this.configuration)
      .apiSectorSectorArticlesGet(sector, date, perPage, page, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get stripe accessurl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStripeInvoicesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/stripe/invoices`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Receive stripe\'s webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStripeWebhookPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/stripe/webhook`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get stripe accessurl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStripeInvoicesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeAccessUrl>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeInvoicesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Receive stripe\'s webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiStripeWebhookPost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripeWebhookPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StripeApiFp(configuration);
  return {
    /**
     *
     * @summary Get stripe accessurl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStripeInvoicesGet(options?: any): AxiosPromise<StripeAccessUrl> {
      return localVarFp.apiStripeInvoicesGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Receive stripe\'s webhook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiStripeWebhookPost(options?: any): AxiosPromise<void> {
      return localVarFp.apiStripeWebhookPost(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
  /**
   *
   * @summary Get stripe accessurl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StripeApi
   */
  public apiStripeInvoicesGet(options?: any) {
    return StripeApiFp(this.configuration)
      .apiStripeInvoicesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Receive stripe\'s webhook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StripeApi
   */
  public apiStripeWebhookPost(options?: any) {
    return StripeApiFp(this.configuration)
      .apiStripeWebhookPost(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLabelsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/labels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark an entity as read and count visits
     * @param {UserHasReadSchema} [userHasReadSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMarkAsReadPost: async (
      userHasReadSchema?: UserHasReadSchema,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/mark-as-read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userHasReadSchema,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiLabelsGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiLabelsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Mark an entity as read and count visits
     * @param {UserHasReadSchema} [userHasReadSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMarkAsReadPost(
      userHasReadSchema?: UserHasReadSchema,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMarkAsReadPost(
        userHasReadSchema,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommonApiFp(configuration);
  return {
    /**
     *
     * @summary labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiLabelsGet(options?: any): AxiosPromise<void> {
      return localVarFp.apiLabelsGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Mark an entity as read and count visits
     * @param {UserHasReadSchema} [userHasReadSchema]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMarkAsReadPost(userHasReadSchema?: UserHasReadSchema, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiMarkAsReadPost(userHasReadSchema, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
  /**
   *
   * @summary labels
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public apiLabelsGet(options?: any) {
    return CommonApiFp(this.configuration)
      .apiLabelsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark an entity as read and count visits
   * @param {UserHasReadSchema} [userHasReadSchema]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public apiMarkAsReadPost(userHasReadSchema?: UserHasReadSchema, options?: any) {
    return CommonApiFp(this.configuration)
      .apiMarkAsReadPost(userHasReadSchema, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompaniesApi - axios parameter creator
 * @export
 */
export const CompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Company location search
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyLocationGet: async (location?: string, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/geocode/autocomplete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        { query: location },
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Company location search
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyLocationGeoCodeGet: async (
      location?: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/geocode`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        { city: location },
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register Company
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterCompanyPost: async (
      inlineObject2?: InlineObject2,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/register-company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject2,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a complaint for a company.
     * @param {string} company
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyComplaintPost: async (
      company: string,
      complaintRequest?: ComplaintRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyComplaintPost', 'company', company);
      const localVarPath = `/api/companies/{company}/complaint`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        complaintRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Contact a company.
     * @param {string} company
     * @param {InlineObject11} [inlineObject11]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyContactPost: async (
      company: string,
      inlineObject11?: InlineObject11,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyContactPost', 'company', company);
      const localVarPath = `/api/companies/{company}/contact`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject11,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get one company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyGet: async (company: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyGet', 'company', company);
      const localVarPath = `/api/companies/{company}`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get companies for slider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesLatestGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/latest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get companies for slider.
     * @param page
     * @param perPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesWithScoreGet: async (
      page: number,
      perPage: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/latest/review`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Companies for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorCompaniesGet: async (
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorCompaniesGet', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/companies`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (latitude !== undefined) {
        localVarQueryParameter['latitude'] = latitude;
      }

      if (longitude !== undefined) {
        localVarQueryParameter['longitude'] = longitude;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch a company.
     * @param {string} company
     * @param {InlineObject10} [inlineObject10]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyPatch: async (
      company: string,
      inlineObject10?: InlineObject10,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyPatch', 'company', company);
      const localVarPath = `/api/companies/{company}`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject10,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get scores
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyScoreGet: async (
      company: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyScoreGet', 'company', company);
      const localVarPath = `/api/companies/{company}/score`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get shortlinks for a company.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyShortlinksGet: async (options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      const localVarPath = `/api/companies/shorturls`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Total count of companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCounterGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/counter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get data for pro dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesProDashboardGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/pro-dashboard`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get one company by vat.
     * @param {string} vatNr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesVatVatNrGet: async (vatNr: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'vatNr' is not null or undefined
      assertParamExists('apiCompaniesVatVatNrGet', 'vatNr', vatNr);
      const localVarPath = `/api/companies/vat/{vatNr}`.replace(
        `{${'vatNr'}}`,
        encodeURIComponent(String(vatNr))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompaniesApi - functional programming interface
 * @export
 */
export const CompaniesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary find location Company
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyLocationGet(
      location?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyLocationGet(
        location,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary find location Company
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompanyLocationGeoCodeGet(
      location?: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyLocationGeoCodeGet(
        location,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },

    /**
     *
     * @summary Register Company
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRegisterCompanyPost(
      inlineObject2?: InlineObject2,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRegisterCompanyPost(
        inlineObject2,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a complaint for a company.
     * @param {string} company
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyComplaintPost(
      company: string,
      complaintRequest?: ComplaintRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complaint>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyComplaintPost(
        company,
        complaintRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Contact a company.
     * @param {string} company
     * @param {InlineObject11} [inlineObject11]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyContactPost(
      company: string,
      inlineObject11?: InlineObject11,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyContactPost(
        company,
        inlineObject11,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get one company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnonymousCompany>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Patch a company.
     * @param {string} company
     * @param {InlineObject10} [inlineObject10]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyPatch(
      company: string,
      inlineObject10?: InlineObject10,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyPatch(
        company,
        inlineObject10,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get scores
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyScoreGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Score>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyScoreGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get shortlinks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyShortLinksGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShortLinks>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyShortlinksGet(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Total count of companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCounterGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Count>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCounterGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get data for pro dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesProDashboardGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2006>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesProDashboardGet(
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get one company by vat.
     * @param {string} vatNr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesVatVatNrGet(
      vatNr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesVatVatNrGet(
        vatNr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get companies for slider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesLatestGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesLatestGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },

    /**
     *
     * @summary Get companies for score page.
     * @param page
     * @param perPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesWithScoreGet(
      page: number,
      perPage: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesWithScoreGet(
        page,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Companies for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorCompaniesGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2009>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorCompaniesGet(
        sector,
        date,
        perPage,
        latitude,
        longitude,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CompaniesApi - factory interface
 * @export
 */
export const CompaniesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CompaniesApiFp(configuration);
  return {
    /**
     *
     * @summary location Company search
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyLocationGet(location?: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .apiCompanyLocationGet(location, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary location Company search
     * @param {string} location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompanyLocationGeoCodeGet(location?: string, options?: any): AxiosPromise<any> {
      return localVarFp
        .apiCompanyLocationGeoCodeGet(location, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register Company
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterCompanyPost(
      inlineObject2?: InlineObject2,
      options?: any
    ): AxiosPromise<Company> {
      return localVarFp
        .apiAuthRegisterCompanyPost(inlineObject2, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a complaint for a company.
     * @param {string} company
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyComplaintPost(
      company: string,
      complaintRequest?: ComplaintRequest,
      options?: any
    ): AxiosPromise<Complaint> {
      return localVarFp
        .apiCompaniesCompanyComplaintPost(company, complaintRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Contact a company.
     * @param {string} company
     * @param {InlineObject11} [inlineObject11]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyContactPost(
      company: string,
      inlineObject11?: InlineObject11,
      options?: any
    ): AxiosPromise<Message> {
      return localVarFp
        .apiCompaniesCompanyContactPost(company, inlineObject11, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get one company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyGet(company: string, options?: any): AxiosPromise<AnonymousCompany> {
      return localVarFp
        .apiCompaniesCompanyGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch a company.
     * @param {string} company
     * @param {InlineObject10} [inlineObject10]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyPatch(
      company: string,
      inlineObject10?: InlineObject10,
      options?: any
    ): AxiosPromise<Company> {
      return localVarFp
        .apiCompaniesCompanyPatch(company, inlineObject10, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get scores
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyScoreGet(company: string, options?: any): AxiosPromise<Score> {
      return localVarFp
        .apiCompaniesCompanyScoreGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get shortlinks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyShortlinksGet(options?: any): AxiosPromise<ShortLinks> {
      return localVarFp
        .apiCompaniesCompanyShortLinksGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Total count of companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCounterGet(options?: any): AxiosPromise<Count> {
      return localVarFp.apiCompaniesCounterGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get data for pro dashboard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesProDashboardGet(options?: any): AxiosPromise<InlineResponse2006> {
      return localVarFp
        .apiCompaniesProDashboardGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get one company by vat.
     * @param {string} vatNr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesVatVatNrGet(vatNr: string, options?: any): AxiosPromise<Company> {
      return localVarFp
        .apiCompaniesVatVatNrGet(vatNr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of companies for slider.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesLatestGet(options?: any): AxiosPromise<InlineResponse2009> {
      return localVarFp.apiCompaniesLatestGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of companies for score page.
     * @param page
     * @param perPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesWithScoreGet(
      page: number,
      perPage: number,
      options?: any
    ): AxiosPromise<InlineResponse2009> {
      return localVarFp
        .apiCompaniesWithScoreGet(page, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Companies for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorCompaniesGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): AxiosPromise<InlineResponse2009> {
      return localVarFp
        .apiSectorSectorCompaniesGet(sector, date, perPage, latitude, longitude, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompaniesApi - object-oriented interface
 * @export
 * @class CompaniesApi
 * @extends {BaseAPI}
 */
export class CompaniesApi extends BaseAPI {
  /**
   *
   * @summary Register Company
   * @param {InlineObject2} [inlineObject2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiAuthRegisterCompanyPost(inlineObject2?: InlineObject2, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiAuthRegisterCompanyPost(inlineObject2, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a complaint for a company.
   * @param {string} company
   * @param {ComplaintRequest} [complaintRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyComplaintPost(
    company: string,
    complaintRequest?: ComplaintRequest,
    options?: any
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyComplaintPost(company, complaintRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Contact a company.
   * @param {string} company
   * @param {InlineObject11} [inlineObject11]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyContactPost(
    company: string,
    inlineObject11?: InlineObject11,
    options?: any
  ) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyContactPost(company, inlineObject11, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get one company.
   * @param {string} company
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyGet(company: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyGet(company, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company list for slider.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesLatestGet(options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesLatestGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company list for score page.
   * @param page
   * @param perPage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesWithScoreGet(page, perPage, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesWithScoreGet(page, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Company list for sector.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompanies(options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiSectorSectorCompaniesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch a company.
   * @param {string} company
   * @param {InlineObject10} [inlineObject10]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyPatch(company: string, inlineObject10?: InlineObject10, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyPatch(company, inlineObject10, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get scores
   * @param {string} company
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCompanyScoreGet(company: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCompanyScoreGet(company, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Total count of companies
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesCounterGet(options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesCounterGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get data for pro dashboard.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesProDashboardGet(options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesProDashboardGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get one company by vat.
   * @param {string} vatNr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompaniesApi
   */
  public apiCompaniesVatVatNrGet(vatNr: string, options?: any) {
    return CompaniesApiFp(this.configuration)
      .apiCompaniesVatVatNrGet(vatNr, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * EmployeesApi - axios parameter creator
 * @export
 */
export const EmployeesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Remove an employee
     * @param {string} employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesEmployeeDelete: async (
      employee: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'employee' is not null or undefined
      assertParamExists('apiCompaniesEmployeesEmployeeDelete', 'employee', employee);
      const localVarPath = `/api/companies/employees/{employee}`.replace(
        `{${'employee'}}`,
        encodeURIComponent(String(employee))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch an employee
     * @param {string} employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesEmployeePatch: async (
      employee: string,
      employeeRequest?: EmployeeRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'employee' is not null or undefined
      assertParamExists('apiCompaniesEmployeesEmployeePatch', 'employee', employee);
      const localVarPath = `/api/companies/employees/{employee}`.replace(
        `{${'employee'}}`,
        encodeURIComponent(String(employee))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        employeeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List employees for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Invite an employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesPost: async (
      employeeRequest?: EmployeeRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        employeeRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmployeesApi - functional programming interface
 * @export
 */
export const EmployeesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EmployeesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Remove an employee
     * @param {string} employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesEmployeesEmployeeDelete(
      employee: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesEmployeesEmployeeDelete(
        employee,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Patch an employee
     * @param {string} employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesEmployeesEmployeePatch(
      employee: string,
      employeeRequest?: EmployeeRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesEmployeesEmployeePatch(
        employee,
        employeeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List employees for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesEmployeesGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2007>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesEmployeesGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Invite an employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesEmployeesPost(
      employeeRequest?: EmployeeRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesEmployeesPost(
        employeeRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmployeesApi - factory interface
 * @export
 */
export const EmployeesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EmployeesApiFp(configuration);
  return {
    /**
     *
     * @summary Remove an employee
     * @param {string} employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesEmployeeDelete(employee: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiCompaniesEmployeesEmployeeDelete(employee, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch an employee
     * @param {string} employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesEmployeePatch(
      employee: string,
      employeeRequest?: EmployeeRequest,
      options?: any
    ): AxiosPromise<User> {
      return localVarFp
        .apiCompaniesEmployeesEmployeePatch(employee, employeeRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List employees for company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesGet(options?: any): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .apiCompaniesEmployeesGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Invite an employee
     * @param {EmployeeRequest} [employeeRequest] Invite an employee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesEmployeesPost(
      employeeRequest?: EmployeeRequest,
      options?: any
    ): AxiosPromise<User> {
      return localVarFp
        .apiCompaniesEmployeesPost(employeeRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * EmployeesApi - object-oriented interface
 * @export
 * @class EmployeesApi
 * @extends {BaseAPI}
 */
export class EmployeesApi extends BaseAPI {
  /**
   *
   * @summary Remove an employee
   * @param {string} employee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public apiCompaniesEmployeesEmployeeDelete(employee: string, options?: any) {
    return EmployeesApiFp(this.configuration)
      .apiCompaniesEmployeesEmployeeDelete(employee, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch an employee
   * @param {string} employee
   * @param {EmployeeRequest} [employeeRequest] Invite an employee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public apiCompaniesEmployeesEmployeePatch(
    employee: string,
    employeeRequest?: EmployeeRequest,
    options?: any
  ) {
    return EmployeesApiFp(this.configuration)
      .apiCompaniesEmployeesEmployeePatch(employee, employeeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List employees for company
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public apiCompaniesEmployeesGet(options?: any) {
    return EmployeesApiFp(this.configuration)
      .apiCompaniesEmployeesGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Invite an employee
   * @param {EmployeeRequest} [employeeRequest] Invite an employee
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmployeesApi
   */
  public apiCompaniesEmployeesPost(employeeRequest?: EmployeeRequest, options?: any) {
    return EmployeesApiFp(this.configuration)
      .apiCompaniesEmployeesPost(employeeRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InvitationsApi - axios parameter creator
 * @export
 */
export const InvitationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload an excel
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesInviteForReviewExcelPost: async (
      inlineObject8?: InlineObject8,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/invite-for-review/excel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject8,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Invite for reviews
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesInviteForReviewPost: async (
      inlineObject9?: InlineObject9,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/companies/invite-for-review`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject9,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InvitationsApi - functional programming interface
 * @export
 */
export const InvitationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvitationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Upload an excel
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesInviteForReviewExcelPost(
      inlineObject8?: InlineObject8,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesInviteForReviewExcelPost(
        inlineObject8,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Invite for reviews
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesInviteForReviewPost(
      inlineObject9?: InlineObject9,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesInviteForReviewPost(
        inlineObject9,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InvitationsApi - factory interface
 * @export
 */
export const InvitationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InvitationsApiFp(configuration);
  return {
    /**
     *
     * @summary Upload an excel
     * @param {InlineObject8} [inlineObject8]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesInviteForReviewExcelPost(
      inlineObject8?: InlineObject8,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiCompaniesInviteForReviewExcelPost(inlineObject8, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Invite for reviews
     * @param {InlineObject9} [inlineObject9]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesInviteForReviewPost(
      inlineObject9?: InlineObject9,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiCompaniesInviteForReviewPost(inlineObject9, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InvitationsApi - object-oriented interface
 * @export
 * @class InvitationsApi
 * @extends {BaseAPI}
 */
export class InvitationsApi extends BaseAPI {
  /**
   *
   * @summary Upload an excel
   * @param {InlineObject8} [inlineObject8]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitationsApi
   */
  public apiCompaniesInviteForReviewExcelPost(inlineObject8?: InlineObject8, options?: any) {
    return InvitationsApiFp(this.configuration)
      .apiCompaniesInviteForReviewExcelPost(inlineObject8, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Invite for reviews
   * @param {InlineObject9} [inlineObject9]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvitationsApi
   */
  public apiCompaniesInviteForReviewPost(inlineObject9?: InlineObject9, options?: any) {
    return InvitationsApiFp(this.configuration)
      .apiCompaniesInviteForReviewPost(inlineObject9, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List jobs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobPageGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/job-page`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a job.
     * @param {string} jobPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobPageJobPageGet: async (jobPage: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobPage' is not null or undefined
      assertParamExists('apiJobPageJobPageGet', 'jobPage', jobPage);
      const localVarPath = `/api/job-page/{jobPage}`.replace(
        `{${'jobPage'}}`,
        encodeURIComponent(String(jobPage))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List jobs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobPageGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobPageGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a job.
     * @param {string} jobPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiJobPageJobPageGet(
      jobPage: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiJobPageJobPageGet(
        jobPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = JobsApiFp(configuration);
  return {
    /**
     *
     * @summary List jobs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobPageGet(options?: any): AxiosPromise<InlineResponse200> {
      return localVarFp.apiJobPageGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a job.
     * @param {string} jobPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiJobPageJobPageGet(jobPage: string, options?: any): AxiosPromise<JobPage> {
      return localVarFp
        .apiJobPageJobPageGet(jobPage, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
  /**
   *
   * @summary List jobs.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public apiJobPageGet(options?: any) {
    return JobsApiFp(this.configuration)
      .apiJobPageGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a job.
   * @param {string} jobPage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobsApi
   */
  public apiJobPageJobPageGet(jobPage: string, options?: any) {
    return JobsApiFp(this.configuration)
      .apiJobPageJobPageGet(jobPage, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Upload a file
     * @param {FileCreation} [fileCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFilePost: async (fileCreation?: FileCreation, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        fileCreation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload image for an entity.
     * @param {number} modelId
     * @param {ImageCreation} [imageCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiImageUploadModelIdPost: async (
      modelId: number,
      imageCreation?: ImageCreation,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'modelId' is not null or undefined
      assertParamExists('apiImageUploadModelIdPost', 'modelId', modelId);
      const localVarPath = `/api/image-upload/{modelId}`.replace(
        `{${'modelId'}}`,
        encodeURIComponent(String(modelId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        imageCreation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete media by id
     * @param {string} media
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMediaMediaDelete: async (media: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'media' is not null or undefined
      assertParamExists('apiMediaMediaDelete', 'media', media);
      const localVarPath = `/api/media/{media}`.replace(
        `{${'media'}}`,
        encodeURIComponent(String(media))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get media by id & filename
     * @param {string} media
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMediaMediaFilenameGet: async (
      media: string,
      filename: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'media' is not null or undefined
      assertParamExists('apiMediaMediaFilenameGet', 'media', media);
      // verify required parameter 'filename' is not null or undefined
      assertParamExists('apiMediaMediaFilenameGet', 'filename', filename);
      const localVarPath = `/api/media/{media}/{filename}`
        .replace(`{${'media'}}`, encodeURIComponent(String(media)))
        .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MediaApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Upload a file
     * @param {FileCreation} [fileCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFilePost(
      fileCreation?: FileCreation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiFilePost(fileCreation, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Upload image for an entity.
     * @param {number} modelId
     * @param {ImageCreation} [imageCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiImageUploadModelIdPost(
      modelId: number,
      imageCreation?: ImageCreation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiImageUploadModelIdPost(
        modelId,
        imageCreation,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete media by id
     * @param {string} media
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMediaMediaDelete(
      media: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMediaMediaDelete(media, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get media by id & filename
     * @param {string} media
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiMediaMediaFilenameGet(
      media: string,
      filename: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiMediaMediaFilenameGet(
        media,
        filename,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MediaApiFp(configuration);
  return {
    /**
     *
     * @summary Upload a file
     * @param {FileCreation} [fileCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFilePost(fileCreation?: FileCreation, options?: any): AxiosPromise<MediaResponse> {
      return localVarFp
        .apiFilePost(fileCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload image for an entity.
     * @param {number} modelId
     * @param {ImageCreation} [imageCreation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiImageUploadModelIdPost(
      modelId: number,
      imageCreation?: ImageCreation,
      options?: any
    ): AxiosPromise<MediaResponse> {
      return localVarFp
        .apiImageUploadModelIdPost(modelId, imageCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete media by id
     * @param {string} media
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMediaMediaDelete(media: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiMediaMediaDelete(media, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get media by id & filename
     * @param {string} media
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiMediaMediaFilenameGet(
      media: string,
      filename: string,
      options?: any
    ): AxiosPromise<MediaResponse> {
      return localVarFp
        .apiMediaMediaFilenameGet(media, filename, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
  /**
   *
   * @summary Upload a file
   * @param {FileCreation} [fileCreation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaApi
   */
  public apiFilePost(fileCreation?: FileCreation, options?: any) {
    return MediaApiFp(this.configuration)
      .apiFilePost(fileCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload image for an entity.
   * @param {number} modelId
   * @param {ImageCreation} [imageCreation]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaApi
   */
  public apiImageUploadModelIdPost(modelId: number, imageCreation?: ImageCreation, options?: any) {
    return MediaApiFp(this.configuration)
      .apiImageUploadModelIdPost(modelId, imageCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete media by id
   * @param {string} media
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaApi
   */
  public apiMediaMediaDelete(media: string, options?: any) {
    return MediaApiFp(this.configuration)
      .apiMediaMediaDelete(media, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get media by id & filename
   * @param {string} media
   * @param {string} filename
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MediaApi
   */
  public apiMediaMediaFilenameGet(media: string, filename: string, options?: any) {
    return MediaApiFp(this.configuration)
      .apiMediaMediaFilenameGet(media, filename, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PagesApi - axios parameter creator
 * @export
 */
export const PagesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary get a basic page.
     * @param {string} basicPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBasicPageBasicPageGet: async (
      basicPage: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'basicPage' is not null or undefined
      assertParamExists('apiBasicPageBasicPageGet', 'basicPage', basicPage);
      const localVarPath = `/api/basic-page/{basicPage}`.replace(
        `{${'basicPage'}}`,
        encodeURIComponent(String(basicPage))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PagesApi - functional programming interface
 * @export
 */
export const PagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PagesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary get a basic page.
     * @param {string} basicPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiBasicPageBasicPageGet(
      basicPage: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicPage>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasicPageBasicPageGet(
        basicPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PagesApi - factory interface
 * @export
 */
export const PagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PagesApiFp(configuration);
  return {
    /**
     *
     * @summary get a basic page.
     * @param {string} basicPage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiBasicPageBasicPageGet(basicPage: string, options?: any): AxiosPromise<BasicPage> {
      return localVarFp
        .apiBasicPageBasicPageGet(basicPage, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PagesApi - object-oriented interface
 * @export
 * @class PagesApi
 * @extends {BaseAPI}
 */
export class PagesApi extends BaseAPI {
  /**
   *
   * @summary get a basic page.
   * @param {string} basicPage
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PagesApi
   */
  public apiBasicPageBasicPageGet(basicPage: string, options?: any) {
    return PagesApiFp(this.configuration)
      .apiBasicPageBasicPageGet(basicPage, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PlansApi - axios parameter creator
 * @export
 */
export const PlansApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List all plans.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/plans`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get one plan.
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansPlanGet: async (plan: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'plan' is not null or undefined
      assertParamExists('apiPlansPlanGet', 'plan', plan);
      const localVarPath = `/api/plans/{plan}`.replace(
        `{${'plan'}}`,
        encodeURIComponent(String(plan))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get next plan (empty=free).
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansPlanNextGet: async (plan: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'plan' is not null or undefined
      assertParamExists('apiPlansPlanNextGet', 'plan', plan);
      const localVarPath = `/api/plans/{plan}/next`.replace(
        `{${'plan'}}`,
        encodeURIComponent(String(plan))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PlansApi - functional programming interface
 * @export
 */
export const PlansApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlansApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List all plans.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPlansGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlansGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get one plan.
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPlansPlanGet(
      plan: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlansPlanGet(plan, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get next plan (empty=free).
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiPlansPlanNextGet(
      plan: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plan>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiPlansPlanNextGet(plan, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PlansApi - factory interface
 * @export
 */
export const PlansApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PlansApiFp(configuration);
  return {
    /**
     *
     * @summary List all plans.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansGet(options?: any): AxiosPromise<InlineResponse2002> {
      return localVarFp.apiPlansGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get one plan.
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansPlanGet(plan: string, options?: any): AxiosPromise<Plan> {
      return localVarFp.apiPlansPlanGet(plan, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get next plan (empty=free).
     * @param {string} plan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiPlansPlanNextGet(plan: string, options?: any): AxiosPromise<Plan> {
      return localVarFp
        .apiPlansPlanNextGet(plan, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PlansApi - object-oriented interface
 * @export
 * @class PlansApi
 * @extends {BaseAPI}
 */
export class PlansApi extends BaseAPI {
  /**
   *
   * @summary List all plans.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public apiPlansGet(options?: any) {
    return PlansApiFp(this.configuration)
      .apiPlansGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get one plan.
   * @param {string} plan
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public apiPlansPlanGet(plan: string, options?: any) {
    return PlansApiFp(this.configuration)
      .apiPlansPlanGet(plan, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get next plan (empty=free).
   * @param {string} plan
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlansApi
   */
  public apiPlansPlanNextGet(plan: string, options?: any) {
    return PlansApiFp(this.configuration)
      .apiPlansPlanNextGet(plan, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PublicationsApi - axios parameter creator
 * @export
 */
export const PublicationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List publications for a company.
     * @param {string} company
     * @param {number} [page] Page number
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyPublicationsGet: async (
      company: string,
      page?: number,
      perPage?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyPublicationsGet', 'company', company);
      const localVarPath = `/api/companies/{company}/publications`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PublicationsApi - functional programming interface
 * @export
 */
export const PublicationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PublicationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List publications for a company.
     * @param {string} company
     * @param {number} [page] Page number
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyPublicationsGet(
      company: string,
      page?: number,
      perPage?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2008>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyPublicationsGet(
        company,
        page,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PublicationsApi - factory interface
 * @export
 */
export const PublicationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PublicationsApiFp(configuration);
  return {
    /**
     *
     * @summary List publications for a company.
     * @param {string} company
     * @param {number} [page] Page number
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyPublicationsGet(
      company: string,
      page?: number,
      perPage?: number,
      options?: any
    ): AxiosPromise<InlineResponse2008> {
      return localVarFp
        .apiCompaniesCompanyPublicationsGet(company, page, perPage, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PublicationsApi - object-oriented interface
 * @export
 * @class PublicationsApi
 * @extends {BaseAPI}
 */
export class PublicationsApi extends BaseAPI {
  /**
   *
   * @summary List publications for a company.
   * @param {string} company
   * @param {number} [page] Page number
   * @param {number} [perPage] number of items per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PublicationsApi
   */
  public apiCompaniesCompanyPublicationsGet(
    company: string,
    page?: number,
    perPage?: number,
    options?: any
  ) {
    return PublicationsApiFp(this.configuration)
      .apiCompaniesCompanyPublicationsGet(company, page, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RealisationApi - axios parameter creator
 * @export
 */
export const RealisationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List realisations for a company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyRealisationsGet: async (
      company: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyRealisationsGet', 'company', company);
      const localVarPath = `/api/companies/{company}/realisations`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Realisations
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationGet: async (
      date?: string,
      perPage?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/realisation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get most read realisations.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationMostReadGet: async (
      date?: string,
      perPage?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/realisation/most-read`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create realisation.
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationPost: async (
      realisationCreation?: RealisationCreation,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/realisation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        realisationCreation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationDelete: async (
      realisation: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'realisation' is not null or undefined
      assertParamExists('apiRealisationRealisationDelete', 'realisation', realisation);
      const localVarPath = `/api/realisation/{realisation}`.replace(
        `{${'realisation'}}`,
        encodeURIComponent(String(realisation))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationGet: async (
      realisation: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'realisation' is not null or undefined
      assertParamExists('apiRealisationRealisationGet', 'realisation', realisation);
      const localVarPath = `/api/realisation/{realisation}`.replace(
        `{${'realisation'}}`,
        encodeURIComponent(String(realisation))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a realisation
     * @param {string} realisation
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationPatch: async (
      realisation: string,
      realisationCreation?: RealisationCreation,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'realisation' is not null or undefined
      assertParamExists('apiRealisationRealisationPatch', 'realisation', realisation);
      const localVarPath = `/api/realisation/{realisation}`.replace(
        `{${'realisation'}}`,
        encodeURIComponent(String(realisation))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        realisationCreation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Realisations for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorRealisationsGet: async (
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorRealisationsGet', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/realisations`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (latitude !== undefined) {
        localVarQueryParameter['latitude'] = latitude;
      }

      if (longitude !== undefined) {
        localVarQueryParameter['longitude'] = longitude;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RealisationApi - functional programming interface
 * @export
 */
export const RealisationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RealisationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List realisations for a company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyRealisationsGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyRealisationsGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Realisations
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationGet(
      date?: string,
      perPage?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationGet(
        date,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get most read realisations.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationMostReadGet(
      date?: string,
      perPage?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationMostReadGet(
        date,
        perPage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create realisation.
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationPost(
      realisationCreation?: RealisationCreation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Realisation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationPost(
        realisationCreation,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Delete Realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationRealisationDelete(
      realisation: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationRealisationDelete(
        realisation,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationRealisationGet(
      realisation: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Realisation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationRealisationGet(
        realisation,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a realisation
     * @param {string} realisation
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiRealisationRealisationPatch(
      realisation: string,
      realisationCreation?: RealisationCreation,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Realisation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiRealisationRealisationPatch(
        realisation,
        realisationCreation,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Realisations for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorRealisationsGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorRealisationsGet(
        sector,
        date,
        perPage,
        latitude,
        longitude,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RealisationApi - factory interface
 * @export
 */
export const RealisationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RealisationApiFp(configuration);
  return {
    /**
     *
     * @summary List realisations for a company.
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyRealisationsGet(
      company: string,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .apiCompaniesCompanyRealisationsGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Realisations
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationGet(
      date?: string,
      perPage?: number,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .apiRealisationGet(date, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get most read realisations.
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationMostReadGet(
      date?: string,
      perPage?: number,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .apiRealisationMostReadGet(date, perPage, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create realisation.
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationPost(
      realisationCreation?: RealisationCreation,
      options?: any
    ): AxiosPromise<Realisation> {
      return localVarFp
        .apiRealisationPost(realisationCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationDelete(realisation: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiRealisationRealisationDelete(realisation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a realisation
     * @param {string} realisation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationGet(realisation: string, options?: any): AxiosPromise<Realisation> {
      return localVarFp
        .apiRealisationRealisationGet(realisation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a realisation
     * @param {string} realisation
     * @param {RealisationCreation} [realisationCreation] Realisation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiRealisationRealisationPatch(
      realisation: string,
      realisationCreation?: RealisationCreation,
      options?: any
    ): AxiosPromise<Realisation> {
      return localVarFp
        .apiRealisationRealisationPatch(realisation, realisationCreation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Realisations for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorRealisationsGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .apiSectorSectorRealisationsGet(sector, date, perPage, latitude, longitude, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RealisationApi - object-oriented interface
 * @export
 * @class RealisationApi
 * @extends {BaseAPI}
 */
export class RealisationApi extends BaseAPI {
  /**
   *
   * @summary List realisations for a company.
   * @param {string} company
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiCompaniesCompanyRealisationsGet(company: string, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiCompaniesCompanyRealisationsGet(company, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Realisations
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationGet(date?: string, perPage?: number, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiRealisationGet(date, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get most read realisations.
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationMostReadGet(date?: string, perPage?: number, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiRealisationMostReadGet(date, perPage, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create realisation.
   * @param {RealisationCreation} [realisationCreation] Realisation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationPost(realisationCreation?: RealisationCreation, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiRealisationPost(realisationCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Realisation
   * @param {string} realisation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationRealisationDelete(realisation: string, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiRealisationRealisationDelete(realisation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a realisation
   * @param {string} realisation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationRealisationGet(realisation: string, options?: any) {
    return RealisationApiFp(this.configuration)
      .apiRealisationRealisationGet(realisation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a realisation
   * @param {string} realisation
   * @param {RealisationCreation} [realisationCreation] Realisation data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiRealisationRealisationPatch(
    realisation: string,
    realisationCreation?: RealisationCreation,
    options?: any
  ) {
    return RealisationApiFp(this.configuration)
      .apiRealisationRealisationPatch(realisation, realisationCreation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Realisations for a sector.
   * @param {string} sector
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {number} [latitude] latitude
   * @param {number} [longitude] longitude
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RealisationApi
   */
  public apiSectorSectorRealisationsGet(
    sector: string,
    date?: string,
    perPage?: number,
    latitude?: number,
    longitude?: number,
    options?: any
  ) {
    return RealisationApiFp(this.configuration)
      .apiSectorSectorRealisationsGet(sector, date, perPage, latitude, longitude, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ReviewsApi - axios parameter creator
 * @export
 */
export const ReviewsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyReviewsAllGet: async (
      company: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyReviewsAllGet', 'company', company);
      const localVarPath = `/api/companies/{company}/reviews/all`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyReviewsGet: async (
      company: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyReviewsGet', 'company', company);
      const localVarPath = `/api/companies/{company}/reviews`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List google reviews for a company".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyGoogleReviewsGet: async (
      company: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'company' is not null or undefined
      assertParamExists('apiCompaniesCompanyGoogleReviewsGet', 'company', company);
      const localVarPath = `/api/companies/{company}/google-reviews`.replace(
        `{${'company'}}`,
        encodeURIComponent(String(company))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Patch feedback for a review
     * @param {string} feedback
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFeedbackFeedbackPatch: async (
      feedback: string,
      inlineObject6?: InlineObject6,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'feedback' is not null or undefined
      assertParamExists('apiFeedbackFeedbackPatch', 'feedback', feedback);
      const localVarPath = `/api/feedback/{feedback}`.replace(
        `{${'feedback'}}`,
        encodeURIComponent(String(feedback))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject6,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get official procon list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProConPointsGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/pro-con-points`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create new pro or con point.
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProConPointsPost: async (
      inlineObject7?: InlineObject7,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/pro-con-points`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject7,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary chatgpt review suggestions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewSuggestionAiGet: async (body, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/review/generate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Total count of reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewCounterGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/review/counter/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get reviews by geolocation
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewGeolocationGet: async (
      latitude?: number,
      longitude?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/review/geolocation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (latitude !== undefined) {
        localVarQueryParameter['latitude'] = latitude;
      }

      if (longitude !== undefined) {
        localVarQueryParameter['longitude'] = longitude;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get latest reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewLatestGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/review/latest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a review.
     * @param {FormData} [FormData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewPost: async (
      type: String,
      formData?: FormData,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/review`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...headersFromBaseOptions,
        ...options.headers,
        ...localVarHeaderParameter,
      };

      localVarRequestOptions.data = serializeDataIfNeeded(
        formData,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create a complaint for a review.
     * @param {string} review
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewComplaintPost: async (
      review: string,
      complaintRequest?: ComplaintRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewComplaintPost', 'review', review);
      const localVarPath = `/api/review/{review}/complaint`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        complaintRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark/unmark a review as favorite
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewFavoritePatch: async (
      review: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewFavoritePatch', 'review', review);
      const localVarPath = `/api/review/{review}/favorite`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create feedback for a review
     * @param {string} review
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewFeedbackPost: async (
      review: string,
      inlineObject5?: InlineObject5,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewFeedbackPost', 'review', review);
      const localVarPath = `/api/review/{review}/feedback`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject5,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get review.
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewGet: async (review: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewGet', 'review', review);
      const localVarPath = `/api/review/{review}/unverified`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List id\'s of all reviews of same company
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewIdListGet: async (review: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewIdListGet', 'review', review);
      const localVarPath = `/api/review/{review}/id-list`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend email verification for review.
     * @param {string} review
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewVerificationNotificationPost: async (
      review: string,
      inlineObject4?: InlineObject4,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewVerificationNotificationPost', 'review', review);
      const localVarPath = `/api/review/{review}/verification-notification`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject4,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify review by code
     * @param {string} review
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewVerifyPost: async (
      review: string,
      codeVerification?: CodeVerification,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'review' is not null or undefined
      assertParamExists('apiReviewReviewVerifyPost', 'review', review);
      const localVarPath = `/api/review/{review}/verify`.replace(
        `{${'review'}}`,
        encodeURIComponent(String(review))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        codeVerification,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List Reviews for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorReviewsGet: async (
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorReviewsGet', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/reviews`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (date !== undefined) {
        localVarQueryParameter['date'] = date;
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage;
      }

      if (latitude !== undefined) {
        localVarQueryParameter['latitude'] = latitude;
      }

      if (longitude !== undefined) {
        localVarQueryParameter['longitude'] = longitude;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReviewsApi - functional programming interface
 * @export
 */
export const ReviewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ReviewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyReviewsAllGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyReviewsAllGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyReviewsGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyReviewsGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List google reviews for a company ".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiCompaniesCompanyGoogleReviewsGet(
      company: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesCompanyGoogleReviewsGet(
        company,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Patch feedback for a review
     * @param {string} feedback
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiFeedbackFeedbackPatch(
      feedback: string,
      inlineObject6?: InlineObject6,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiFeedbackFeedbackPatch(
        feedback,
        inlineObject6,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get official procon list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProConPointsGet(
      options?: any
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ data: Array<ProConPoint> }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProConPointsGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create new pro or con point.
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiProConPointsPost(
      inlineObject7?: InlineObject7,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProConPoint>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiProConPointsPost(
        inlineObject7,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Total count of reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewSuggestionAiGet(
      body,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewSuggestionAiGet(
        body,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Total count of reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewCounterGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Count>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewCounterGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get reviews by geolocation
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewGeolocationGet(
      latitude?: number,
      longitude?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewGeolocationGet(
        latitude,
        longitude,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get latest reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewLatestGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewLatestGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a review.
     * @param {InlineObject3} [inlineObject3]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewPost(
      type: String,
      formData?: FormData,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewPost(
        type,
        formData,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create a complaint for a review.
     * @param {string} review
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewComplaintPost(
      review: string,
      complaintRequest?: ComplaintRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complaint>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewComplaintPost(
        review,
        complaintRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Mark/unmark a review as favorite
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewFavoritePatch(
      review: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewFavoritePatch(
        review,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Create feedback for a review
     * @param {string} review
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewFeedbackPost(
      review: string,
      inlineObject5?: InlineObject5,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewFeedbackPost(
        review,
        inlineObject5,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get review.
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewGet(
      review: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewGet(review, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List id\'s of all reviews of same company
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewIdListGet(
      review: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewIdListGet(
        review,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Resend email verification for review.
     * @param {string} review
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewVerificationNotificationPost(
      review: string,
      inlineObject4?: InlineObject4,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewVerificationNotificationPost(
        review,
        inlineObject4,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Verify review by code
     * @param {string} review
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiReviewReviewVerifyPost(
      review: string,
      codeVerification?: CodeVerification,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiReviewReviewVerifyPost(
        review,
        codeVerification,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List Reviews for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorReviewsGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorReviewsGet(
        sector,
        date,
        perPage,
        latitude,
        longitude,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReviewsApi - factory interface
 * @export
 */
export const ReviewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ReviewsApiFp(configuration);
  return {
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyReviewsAllGet(
      company: string,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiCompaniesCompanyReviewsAllGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyReviewsGet(
      company: string,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiCompaniesCompanyReviewsGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List google reviews for a company
     * @param {string} company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiCompaniesCompanyGoogleReviewsGet(
      company: string,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiCompaniesCompanyGoogleReviewsGet(company, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Patch feedback for a review
     * @param {string} feedback
     * @param {InlineObject6} [inlineObject6]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiFeedbackFeedbackPatch(
      feedback: string,
      inlineObject6?: InlineObject6,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiFeedbackFeedbackPatch(feedback, inlineObject6, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get official procon list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProConPointsGet(options?: any): AxiosPromise<{ data: Array<ProConPoint> }> {
      return localVarFp.apiProConPointsGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create new pro or con point.
     * @param {InlineObject7} [inlineObject7]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiProConPointsPost(inlineObject7?: InlineObject7, options?: any): AxiosPromise<ProConPoint> {
      return localVarFp
        .apiProConPointsPost(inlineObject7, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Total count of reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewCounterGet(options?: any): AxiosPromise<Count> {
      return localVarFp.apiReviewCounterGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary ai suggestion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewSuggestionAiGet(body, options?: any): AxiosPromise<string> {
      return localVarFp
        .apiReviewSuggestionAiGet(body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get reviews by geolocation
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewGeolocationGet(
      latitude?: number,
      longitude?: number,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiReviewGeolocationGet(latitude, longitude, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get latest reviews
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewLatestGet(options?: any): AxiosPromise<InlineResponse2004> {
      return localVarFp.apiReviewLatestGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create a review.
     * @param {InlineObject3} [inlineObject3]
     * @param {ReviewForm} [reviewForm]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewPost(form: ReviewForm, options?: any): AxiosPromise<Review> {
      let formData;

      formData = form.data;
      return localVarFp.apiReviewPost(form.type, formData, options).then((request) => {
        return request(axios, basePath);
      });
    },
    /**
     *
     * @summary Create a complaint for a review.
     * @param {string} review
     * @param {ComplaintRequest} [complaintRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewComplaintPost(
      review: string,
      complaintRequest?: ComplaintRequest,
      options?: any
    ): AxiosPromise<Complaint> {
      return localVarFp
        .apiReviewReviewComplaintPost(review, complaintRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Mark/unmark a review as favorite
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewFavoritePatch(review: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiReviewReviewFavoritePatch(review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create feedback for a review
     * @param {string} review
     * @param {InlineObject5} [inlineObject5]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewFeedbackPost(
      review: string,
      inlineObject5?: InlineObject5,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiReviewReviewFeedbackPost(review, inlineObject5, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get review.
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewGet(review: string, options?: any): AxiosPromise<Review> {
      return localVarFp
        .apiReviewReviewGet(review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List id\'s of all reviews of same company
     * @param {string} review
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewIdListGet(review: string, options?: any): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiReviewReviewIdListGet(review, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resend email verification for review.
     * @param {string} review
     * @param {InlineObject4} [inlineObject4]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewVerificationNotificationPost(
      review: string,
      inlineObject4?: InlineObject4,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiReviewReviewVerificationNotificationPost(review, inlineObject4, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify review by code
     * @param {string} review
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiReviewReviewVerifyPost(
      review: string,
      codeVerification?: CodeVerification,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiReviewReviewVerifyPost(review, codeVerification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List Reviews for a sector.
     * @param {string} sector
     * @param {string} [date] Order by date \&#39;desc/asc\&#39;
     * @param {number} [perPage] number of items per page
     * @param {number} [latitude] latitude
     * @param {number} [longitude] longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorReviewsGet(
      sector: string,
      date?: string,
      perPage?: number,
      latitude?: number,
      longitude?: number,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .apiSectorSectorReviewsGet(sector, date, perPage, latitude, longitude, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ReviewsApi - object-oriented interface
 * @export
 * @class ReviewsApi
 * @extends {BaseAPI}
 */
export class ReviewsApi extends BaseAPI {
  /**
   *
   * @summary List reviews for a company queryparams: \"?date=asc&rating=asc&perPage=10\".
   * @param {string} company
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiCompaniesCompanyReviewsGet(company: string, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiCompaniesCompanyReviewsGet(company, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Patch feedback for a review
   * @param {string} feedback
   * @param {InlineObject6} [inlineObject6]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiFeedbackFeedbackPatch(feedback: string, inlineObject6?: InlineObject6, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiFeedbackFeedbackPatch(feedback, inlineObject6, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get official procon list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiProConPointsGet(options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiProConPointsGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create new pro or con point.
   * @param {InlineObject7} [inlineObject7]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiProConPointsPost(inlineObject7?: InlineObject7, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiProConPointsPost(inlineObject7, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Total count of reviews
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewCounterGet(options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewCounterGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get reviews by geolocation
   * @param {number} [latitude] latitude
   * @param {number} [longitude] longitude
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewGeolocationGet(latitude?: number, longitude?: number, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewGeolocationGet(latitude, longitude, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get latest reviews
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewLatestGet(options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewLatestGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a review.
   * @param {InlineObject3} [inlineObject3]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewPost(type: String, formData?: FormData, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewPost(type, formData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create a complaint for a review.
   * @param {string} review
   * @param {ComplaintRequest} [complaintRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewComplaintPost(
    review: string,
    complaintRequest?: ComplaintRequest,
    options?: any
  ) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewComplaintPost(review, complaintRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark/unmark a review as favorite
   * @param {string} review
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewFavoritePatch(review: string, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewFavoritePatch(review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create feedback for a review
   * @param {string} review
   * @param {InlineObject5} [inlineObject5]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewFeedbackPost(review: string, inlineObject5?: InlineObject5, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewFeedbackPost(review, inlineObject5, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get review.
   * @param {string} review
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewGet(review: string, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewGet(review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List id\'s of all reviews of same company
   * @param {string} review
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewIdListGet(review: string, options?: any) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewIdListGet(review, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend email verification for review.
   * @param {string} review
   * @param {InlineObject4} [inlineObject4]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewVerificationNotificationPost(
    review: string,
    inlineObject4?: InlineObject4,
    options?: any
  ) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewVerificationNotificationPost(review, inlineObject4, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify review by code
   * @param {string} review
   * @param {CodeVerification} [codeVerification] Verify phone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiReviewReviewVerifyPost(
    review: string,
    codeVerification?: CodeVerification,
    options?: any
  ) {
    return ReviewsApiFp(this.configuration)
      .apiReviewReviewVerifyPost(review, codeVerification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List Reviews for a sector.
   * @param {string} sector
   * @param {string} [date] Order by date \&#39;desc/asc\&#39;
   * @param {number} [perPage] number of items per page
   * @param {number} [latitude] latitude
   * @param {number} [longitude] longitude
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReviewsApi
   */
  public apiSectorSectorReviewsGet(
    sector: string,
    date?: string,
    perPage?: number,
    latitude?: number,
    longitude?: number,
    options?: any
  ) {
    return ReviewsApiFp(this.configuration)
      .apiSectorSectorReviewsGet(sector, date, perPage, latitude, longitude, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SectorsApi - axios parameter creator
 * @export
 */
export const SectorsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary List all the sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/sector`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request a new sector
     * @param {RequestSector} [requestSector] Request a new sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorRequestPost: async (
      requestSector?: RequestSector,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/sector/request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestSector,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Couple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorCouplePatch: async (
      sector: string,
      sectorCouple?: SectorCouple,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorCouplePatch', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/couple`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sectorCouple,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Decouple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorDecouplePatch: async (
      sector: string,
      sectorCouple?: SectorCouple,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorDecouplePatch', 'sector', sector);
      const localVarPath = `/api/sector/{sector}/decouple`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sectorCouple,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get a sector by slug or id
     * @param {string} sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorGet: async (sector: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'sector' is not null or undefined
      assertParamExists('apiSectorSectorGet', 'sector', sector);
      const localVarPath = `/api/sector/{sector}`.replace(
        `{${'sector'}}`,
        encodeURIComponent(String(sector))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List all teaser blocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorTeasersGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/sector/teasers`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SectorsApi - functional programming interface
 * @export
 */
export const SectorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SectorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary List all the sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Request a new sector
     * @param {RequestSector} [requestSector] Request a new sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorRequestPost(
      requestSector?: RequestSector,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorRequestPost(
        requestSector,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Couple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorCouplePatch(
      sector: string,
      sectorCouple?: SectorCouple,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorCouplePatch(
        sector,
        sectorCouple,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Decouple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorDecouplePatch(
      sector: string,
      sectorCouple?: SectorCouple,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorDecouplePatch(
        sector,
        sectorCouple,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get a sector by slug or id
     * @param {string} sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorSectorGet(
      sector: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Sector>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorSectorGet(sector, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List all teaser blocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiSectorTeasersGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2005>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiSectorTeasersGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SectorsApi - factory interface
 * @export
 */
export const SectorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SectorsApiFp(configuration);
  return {
    /**
     *
     * @summary List all the sectors
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorGet(options?: any): AxiosPromise<InlineResponse2005> {
      return localVarFp.apiSectorGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Request a new sector
     * @param {RequestSector} [requestSector] Request a new sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorRequestPost(requestSector?: RequestSector, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiSectorRequestPost(requestSector, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Couple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorCouplePatch(
      sector: string,
      sectorCouple?: SectorCouple,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiSectorSectorCouplePatch(sector, sectorCouple, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Decouple sectors
     * @param {string} sector
     * @param {SectorCouple} [sectorCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorDecouplePatch(
      sector: string,
      sectorCouple?: SectorCouple,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiSectorSectorDecouplePatch(sector, sectorCouple, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get a sector by slug or id
     * @param {string} sector
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorSectorGet(sector: string, options?: any): AxiosPromise<Sector> {
      return localVarFp
        .apiSectorSectorGet(sector, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List all teaser blocks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiSectorTeasersGet(options?: any): AxiosPromise<InlineResponse2005> {
      return localVarFp.apiSectorTeasersGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * SectorsApi - object-oriented interface
 * @export
 * @class SectorsApi
 * @extends {BaseAPI}
 */
export class SectorsApi extends BaseAPI {
  /**
   *
   * @summary List all the sectors
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorGet(options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Request a new sector
   * @param {RequestSector} [requestSector] Request a new sector
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorRequestPost(requestSector?: RequestSector, options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorRequestPost(requestSector, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Couple sectors
   * @param {string} sector
   * @param {SectorCouple} [sectorCouple] Couple Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorSectorCouplePatch(sector: string, sectorCouple?: SectorCouple, options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorSectorCouplePatch(sector, sectorCouple, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Decouple sectors
   * @param {string} sector
   * @param {SectorCouple} [sectorCouple] Couple Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorSectorDecouplePatch(sector: string, sectorCouple?: SectorCouple, options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorSectorDecouplePatch(sector, sectorCouple, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get a sector by slug or id
   * @param {string} sector
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorSectorGet(sector: string, options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorSectorGet(sector, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all teaser blocks
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SectorsApi
   */
  public apiSectorTeasersGet(options?: any) {
    return SectorsApiFp(this.configuration)
      .apiSectorTeasersGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Couple tags
     * @param {TagsCouple} [tagsCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagCouplePost: async (tagsCouple?: TagsCouple, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/tag/couple`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tagsCouple,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary List all tags by certain type.
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagTypeGet: async (type: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'type' is not null or undefined
      assertParamExists('apiTagTypeGet', 'type', type);
      const localVarPath = `/api/tag/{type?}`.replace(
        `{${'type'}}`,
        encodeURIComponent(String(type))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Couple tags
     * @param {TagsCouple} [tagsCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTagCouplePost(
      tagsCouple?: TagsCouple,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagCouplePost(
        tagsCouple,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary List all tags by certain type.
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiTagTypeGet(
      type: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiTagTypeGet(type, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TagsApiFp(configuration);
  return {
    /**
     *
     * @summary Couple tags
     * @param {TagsCouple} [tagsCouple] Couple Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagCouplePost(tagsCouple?: TagsCouple, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiTagCouplePost(tagsCouple, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary List all tags by certain type.
     * @param {string} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiTagTypeGet(type: string, options?: any): AxiosPromise<Array<Tag>> {
      return localVarFp.apiTagTypeGet(type, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
  /**
   *
   * @summary Couple tags
   * @param {TagsCouple} [tagsCouple] Couple Tags
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public apiTagCouplePost(tagsCouple?: TagsCouple, options?: any) {
    return TagsApiFp(this.configuration)
      .apiTagCouplePost(tagsCouple, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary List all tags by certain type.
   * @param {string} type
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public apiTagTypeGet(type: string, options?: any) {
    return TagsApiFp(this.configuration)
      .apiTagTypeGet(type, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get company of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthCompanyGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/company`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend email verification.
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthEmailVerificationNotificationPost: async (
      inlineObject1?: InlineObject1,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/email/verification-notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Email verification.
     * @param {string} id
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthEmailVerifyIdHashGet: async (
      id: string,
      hash: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('apiAuthEmailVerifyIdHashGet', 'id', id);
      // verify required parameter 'hash' is not null or undefined
      assertParamExists('apiAuthEmailVerifyIdHashGet', 'hash', hash);
      const localVarPath = `/api/auth/email/verify/{id}/{hash}`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'hash'}}`, encodeURIComponent(String(hash)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Forgot password.
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotPasswordPost: async (
      forgotPasswordRequest?: ForgotPasswordRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/forgot-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        forgotPasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Impersonate.
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthImpersonateHashGet: async (hash: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'hash' is not null or undefined
      assertParamExists('apiAuthImpersonateHashGet', 'hash', hash);
      const localVarPath = `/api/auth/impersonate/{hash}`.replace(
        `{${'hash'}}`,
        encodeURIComponent(String(hash))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Login
     * @param {LoginRequest} [loginRequest] Login Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost: async (
      loginRequest?: LoginRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLogoutPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthMeGet: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Resend phone verification.
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthPhoneVerificationNotificationPost: async (
      inlineObject?: InlineObject,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/phone/verification-notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify phone
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthPhoneVerifyPost: async (
      codeVerification?: CodeVerification,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/phone/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        codeVerification,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Register User
     * @param {RegisterRequest} [registerRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost: async (
      registerRequest?: RegisterRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/register`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        registerRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reset password
     * @param {ResetPasswordRequest} [resetPasswordRequest] Reset Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetPasswordPost: async (
      resetPasswordRequest?: ResetPasswordRequest,
      options: any = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/reset-password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resetPasswordRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get company of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthCompanyGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthCompanyGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Resend email verification.
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthEmailVerificationNotificationPost(
      inlineObject1?: InlineObject1,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthEmailVerificationNotificationPost(
        inlineObject1,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Email verification.
     * @param {string} id
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthEmailVerifyIdHashGet(
      id: string,
      hash: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthEmailVerifyIdHashGet(
        id,
        hash,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Forgot password.
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthForgotPasswordPost(
      forgotPasswordRequest?: ForgotPasswordRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthForgotPasswordPost(
        forgotPasswordRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Impersonate.
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthImpersonateHashGet(
      hash: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthImpersonateHashGet(
        hash,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Login
     * @param {LoginRequest} [loginRequest] Login Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLoginPost(
      loginRequest?: LoginRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLoginPost(
        loginRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthLogoutPost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthLogoutPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthMeGet(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthMeGet(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Resend phone verification.
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthPhoneVerificationNotificationPost(
      inlineObject?: InlineObject,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthPhoneVerificationNotificationPost(
        inlineObject,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Verify phone
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthPhoneVerifyPost(
      codeVerification?: CodeVerification,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthPhoneVerifyPost(
        codeVerification,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRefreshPost(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRefreshPost(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Register User
     * @param {RegisterRequest} [registerRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthRegisterPost(
      registerRequest?: RegisterRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthRegisterPost(
        registerRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Reset password
     * @param {ResetPasswordRequest} [resetPasswordRequest] Reset Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiAuthResetPasswordPost(
      resetPasswordRequest?: ResetPasswordRequest,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthResetPasswordPost(
        resetPasswordRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Get company of the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthCompanyGet(options?: any): AxiosPromise<Company> {
      return localVarFp.apiAuthCompanyGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resend email verification.
     * @param {InlineObject1} [inlineObject1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthEmailVerificationNotificationPost(
      inlineObject1?: InlineObject1,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiAuthEmailVerificationNotificationPost(inlineObject1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Email verification.
     * @param {string} id
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthEmailVerifyIdHashGet(id: string, hash: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiAuthEmailVerifyIdHashGet(id, hash, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Forgot password.
     * @param {ForgotPasswordRequest} [forgotPasswordRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthForgotPasswordPost(
      forgotPasswordRequest?: ForgotPasswordRequest,
      options?: any
    ): AxiosPromise<Message> {
      return localVarFp
        .apiAuthForgotPasswordPost(forgotPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Impersonate.
     * @param {string} hash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthImpersonateHashGet(hash: string, options?: any): AxiosPromise<Token> {
      return localVarFp
        .apiAuthImpersonateHashGet(hash, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Login
     * @param {LoginRequest} [loginRequest] Login Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLoginPost(loginRequest?: LoginRequest, options?: any): AxiosPromise<Token> {
      return localVarFp
        .apiAuthLoginPost(loginRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthLogoutPost(options?: any): AxiosPromise<Message> {
      return localVarFp.apiAuthLogoutPost(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthMeGet(options?: any): AxiosPromise<User> {
      return localVarFp.apiAuthMeGet(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Resend phone verification.
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthPhoneVerificationNotificationPost(
      inlineObject?: InlineObject,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .apiAuthPhoneVerificationNotificationPost(inlineObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify phone
     * @param {CodeVerification} [codeVerification] Verify phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthPhoneVerifyPost(codeVerification?: CodeVerification, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiAuthPhoneVerifyPost(codeVerification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRefreshPost(options?: any): AxiosPromise<Token> {
      return localVarFp.apiAuthRefreshPost(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Register User
     * @param {RegisterRequest} [registerRequest] Register Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthRegisterPost(registerRequest?: RegisterRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .apiAuthRegisterPost(registerRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reset password
     * @param {ResetPasswordRequest} [resetPasswordRequest] Reset Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiAuthResetPasswordPost(
      resetPasswordRequest?: ResetPasswordRequest,
      options?: any
    ): AxiosPromise<Message> {
      return localVarFp
        .apiAuthResetPasswordPost(resetPasswordRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Get company of the current user.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthCompanyGet(options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthCompanyGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend email verification.
   * @param {InlineObject1} [inlineObject1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthEmailVerificationNotificationPost(inlineObject1?: InlineObject1, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthEmailVerificationNotificationPost(inlineObject1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Email verification.
   * @param {string} id
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthEmailVerifyIdHashGet(id: string, hash: string, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthEmailVerifyIdHashGet(id, hash, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Forgot password.
   * @param {ForgotPasswordRequest} [forgotPasswordRequest] Register Data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthForgotPasswordPost(forgotPasswordRequest?: ForgotPasswordRequest, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthForgotPasswordPost(forgotPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Impersonate.
   * @param {string} hash
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthImpersonateHashGet(hash: string, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthImpersonateHashGet(hash, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Login
   * @param {LoginRequest} [loginRequest] Login Data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthLoginPost(loginRequest?: LoginRequest, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthLoginPost(loginRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthLogoutPost(options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthLogoutPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Me
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthMeGet(options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthMeGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Resend phone verification.
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthPhoneVerificationNotificationPost(inlineObject?: InlineObject, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthPhoneVerificationNotificationPost(inlineObject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify phone
   * @param {CodeVerification} [codeVerification] Verify phone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthPhoneVerifyPost(codeVerification?: CodeVerification, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthPhoneVerifyPost(codeVerification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Refresh token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthRefreshPost(options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthRefreshPost(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Register User
   * @param {RegisterRequest} [registerRequest] Register Data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthRegisterPost(registerRequest?: RegisterRequest, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthRegisterPost(registerRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reset password
   * @param {ResetPasswordRequest} [resetPasswordRequest] Reset Password
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public apiAuthResetPasswordPost(resetPasswordRequest?: ResetPasswordRequest, options?: any) {
    return UsersApiFp(this.configuration)
      .apiAuthResetPasswordPost(resetPasswordRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
